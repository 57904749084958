<script lang="ts">
import Component from 'vue-class-component';
import { PropSync, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import service from '@/services/app-http-client';
import PaymentModal from '@/modules/organisation/components/Pricing.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import notificationsDropdown from '@/components/notifications.vue';
import userModule from '@/store/modules/user/user.module';
import { Loading } from '@/modules/auth/store/auth.service';
import Routes from '@/shared/constants/routes';
import VueTour1 from '@/components/v-tour/VueTour1.vue';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { mapState } from 'vuex';
import { appModule } from '@/store/modules/app.module';
import RoleType from '../../../shared/constants/roles';
import { Auth } from '@/store/modules/auth.module';
import { INotificationDto } from '../../models/user/user.dto';
@Component({
  components: {
    PaymentModal,
    notificationsDropdown,
    VueTour1,
    ToastificationContent,
  },
  computed: {
    ...mapState('app', ['darkMode']),
  },
})
export default class HorizontalNavMenu extends Vue {
  @PropSync('active') activeSync!: string;

  public darkMode!: boolean;

  ORGANISATION_DASHBOARD = Routes.ORGANISATION_DASHBOARD;

  ORGANISATION_ACCOMMODATIONS = Routes.ORGANISATION_ACCOMMODATIONS;

  ORGANISATION_BOOKING = Routes.ORGANISATION_BOOKING;

  ORGANISATION_ACCOUNT = Routes.ORGANISATION_ACCOUNT;

  GUEST_DASHBOARD_ROUTE = Routes.GUEST_DASHBOARD_ROUTE;

  ORGANISATION_MEMBERS = Routes.ORGANISATION_MEMBERS;

  ORGANISATION_BILLING = Routes.ORGANISATION_BILLING;

  ORGANISATION_SUBSCRIPTION = Routes.ORGANISATION_SUBSCRIPTION;

  USER_PROFILE_ROUTE = Routes.USER_PROFILE_ROUTE;

  HELP_CENTER = Routes.HELP_CENTER;

  navDropdown = false;

  notificationsDropdown = false;

  isOpen = false;

  menuOpen = false;

  isActive = false;

  activeRole = 'organisation';

  bannerOpen = JSON.parse(localStorage.getItem('bannerOpen') as string) ?? true;

  get alertBannerCnt(): string {
    // return contentModule.alertBannerCnt;
    return 'agasdga';
  }

  get currentRoute(): string {
    return this.$route.path;
  }

  handleRoute(path: any) {
    if (this.$route.path != path) {
      this.menuOpen = false;
      return this.$router.push(path);
    }
    return;
  }

  goToBilling(path: any) {
    if (userModule.member[0].role.role === 'member') {
      return this.showPaymentModal();
    }
    return this.$router.push(path);
  }

  goToSubscription(path: any) {
    if (userModule.member[0].role.role === 'member') {
      return this.showPaymentModal();
    }
    return this.$router.push(path);
  }

  showPaymentModal() {
    tenantModule.setShowPricing(true);
  }

  showToast(variant: any, position: any, icon: any) {
    this.$swal({ title: 'Success', icon: 'error' });

    this.$toast(
      {
        component: ToastificationContent,
        props: {
          title: 'Notification',
          text: '👋 Chocolate oat cake jelly oat cake candy jelly beans pastry.',
          icon,
          variant,
        },
      },
      {
        position,
      },
    );
  }

  closeBanner() {
    this.bannerOpen = false;
    localStorage.setItem('bannerOpen', JSON.stringify(false));
  }

  @Watch('active')
  navigateTo(): void {
    if (this.$route.path !== this.activeSync) {
      this.$router.push({ path: this.activeSync });
    }
  }

  closeMenu(): void {
    this.navDropdown = false;
    this.notificationsDropdown = false;
  }

  get themeText() {
    if (this.darkMode) {
      return 'Light mode';
    } else return 'Dark mode';
  }

  setActiveRole() {
    if (this.activeRole === 'organisation') {
      this.activeRole = 'guest';
      this.$router.push({ path: this.activeSync });

      return this.activeRole;
    }
    this.activeRole = 'organisation';
    return this.activeRole;
  }

  get role(): string[] | undefined {
    if (userModule.role[0]) {
      return userModule.role.map((role: any) => role.roleName);
    }
    return userModule.role;
  }

  get authenticated() {
    return Auth.isAuthenticated;
  }

  get accommodationLength(): boolean {
    return tenantModule.accommodations?.length === 0 || false;
  }

  get credits(): number | undefined {
    return tenantModule.tenant?.subscription?.checkIns;
  }

  get trialActive() {
    return tenantModule.tenant.subscription?.trialActive;
  }

  get accountActive() {
    return tenantModule.tenant.subscription?.isActive;
  }

  async test(): Promise<void> {
    const test = service.get('organisation/subscription/1');
  }

  get profileImage(): string {
    return 'https://w7.pngwing.com/pngs/893/926/png-transparent-silhouette-user-icon-profile-silhouette-silhouette-avatar-profile-silhouette.png';
  }

  get notificationCount(): number | undefined {
    // eslint-disable-next-line camelcase
    return userModule.user?.notifications?.filter((ntf: { is_read: any }) => !ntf.is_read).length;
  }

  get unreadNotifications(): INotificationDto[] | undefined {
    return userModule.user?.notifications;
  }

  get navbarColor(): string {
    return this.$store.state.theme === 'dark' ? '#fff' : '#fff';
  }

  get tourActive() {
    return appModule.tourActive;
  }

  toChat() {
    this.$router.push('/organisation/chat');
  }

  swapTheme() {
    appModule.updateTheme(!appModule.darkMode);
  }
  created() {
    const NavVTourSee = localStorage.getItem('NavVTourSee');
    if (NavVTourSee == null) {
      this.navDropdown = true;
    }
  }
  @Loading
  async logout(): Promise<void> {
    const NavVTourSeen = localStorage.getItem('NavVTourSee');
    localStorage.clear();
    if (NavVTourSeen != null) {
      /*       localStorage.setItem('NavVTourSeen', NavVTourSeen);
       */ window.localStorage.setItem('NavVTourSee', 'false');
      window.localStorage.setItem('BookingVTourSee', 'false');
      window.localStorage.setItem('AccommodationVTourSee', 'false');
    }
    this.$router.replace('/');
    await userModule.logoutUser();
  }

  roleState() {
    return RoleType.Guest;
  }
}
</script>

<template>
  <div class="relative bg-white dark:bg-gray-700 dark:text-white">
    <div class="max-w-full mx-auto px-4 sm:px-6 border-b-2 border-gray-100">
      <div class="flex justify-between items-center py-6 lm:justify-start lm:space-x-10">
        <div class="flex justify-start lm:w-0 lm:flex-1">
          <button v-if="$can('read', 'tenant')" data-v-step="2" @click="handleRoute(ORGANISATION_DASHBOARD)">
            <span class="sr-only">Logo Image</span>
            <img class="w-auto sm:h-16" src="@/assets/images/logo/logo.png" alt="logo_image" />
          </button>
        </div>
        <div class="-mr-2 -my-2 lm:hidden">
          <button
            type="button"
            class="
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-red-500 hover:bg-gray-100
              focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500
            "
            @click="menuOpen = true"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: menu -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <nav class="hidden lm:flex space-x-10 navbar">
          <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
          <button v-if="$can('read', 'tenant')" class="h-full" data-v-step="3" @click="handleRoute(ORGANISATION_DASHBOARD)">
            <span
              class="navbar-title-text dark:text-white"
              :class="{ 'text-red-500 dark:text-red-600': currentRoute == ORGANISATION_DASHBOARD }"
              >Dashboard
            </span>
          </button>

          <button v-if="$can('read', 'booking')" data-v-step="4" @click="handleRoute(ORGANISATION_BOOKING)"
            ><span
              class="navbar-title-text dark:text-white"
              :class="{ 'text-red-500 dark:text-red-600': currentRoute == ORGANISATION_BOOKING }"
            >
              Bookings</span
            >
          </button>
          <button v-if="$can('read', 'accommodation')" data-v-step="5" @click="handleRoute(ORGANISATION_ACCOMMODATIONS)"
            ><span
              class="navbar-title-text dark:text-white"
              :class="{ 'text-red-500 dark:text-red-600': currentRoute == ORGANISATION_ACCOMMODATIONS }"
            >
              Accommodations</span
            >
          </button>
          <button v-if="$can('read', 'accommodation')" data-v-step="6" @click="handleRoute(ORGANISATION_ACCOUNT)"
            ><span
              class="navbar-title-text dark:text-white"
              :class="{ 'text-red-500 dark:text-red-600': currentRoute == ORGANISATION_ACCOUNT }"
            >
              Account</span
            >
          </button>
        </nav>
        <div class="hidden lm:flex items-center justify-end lm:flex-1 lm:w-0">
          <template v-if="$can('read', 'tenant')">
            <span class="hidden sm:block shadow-sm rounded" data-v-step="7">
              <button
                v-if="trialActive || accountActive"
                id="creditsBtn"
                type="button"
                class="red-button-sm"
                @click="goToSubscription(ORGANISATION_SUBSCRIPTION)"
              >
                <span class="px-2"> {{ credits }} Credits </span>
              </button>
              <button v-else-if="!accountActive" id="creditsBtn" class="red-button-sm" @click="goToSubscription(ORGANISATION_SUBSCRIPTION)">
                <font-awesome-icon class="mt-0.5" :icon="['fa', 'exclamation-circle']" />
                <span class="px-2"> {{ credits }} Credits </span>
              </button>
              <button v-else id="trialExpiredBtn" class="red-button-sm" @click="goToSubscription(ORGANISATION_SUBSCRIPTION)">
                <font-awesome-icon class="mt-0.5" :icon="['fa', 'exclamation-circle']" />
                <span class="px-2"> Trial Expired </span>
              </button>
            </span>
          </template>
          <template>
            <div v-tooltip.bottom="{ content: themeText, container: false }" class="menu-control-wrp">
              <div @click="swapTheme">
                <feather-icon
                  :icon="darkMode ? 'SunIcon' : 'MoonIcon'"
                  class="cursor-pointer mx-5 align-middle"
                  :badge="notificationCount"
                />
              </div>
            </div>
            <!-- <div class="menu-control-wrp">
              <div
                :class="{ 'notifications-control': true, active: notificationsDropdown }"
                @click.stop="notificationsDropdown = !notificationsDropdown"
              >
                <feather-icon icon="BellIcon" class="cursor-pointer mx-5 align-middle" :badge="notificationCount" />
              </div>
              <div v-if="notificationsDropdown" v-click-outside="closeMenu" class="notifications-dropdown">
                <notificationsDropdown :unread-notifications="unreadNotifications" :bell-count="notificationCount" />
              </div>
            </div> -->

            <div class="menu-control-wrp">
              <div :class="{ 'menu-control': true, active: navDropdown }" @click.stop="navDropdown = !navDropdown">
                <font-awesome-icon icon="bars" />
                <div class="icon p-2">
                  <font-awesome-icon icon="user" />
                </div>
              </div>
              <div v-if="navDropdown" v-click-outside="closeMenu" class="nav-dropdown dark:bg-gray-700">
                <ul>
                  <li @click="handleRoute(HELP_CENTER)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-help-circle"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div class="ml-2" data-v-step="1"> Help Center</div>
                  </li>
                  <vs-divider></vs-divider>
                  <li @click="logout">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-log-out"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    <div class="ml-2">Logout </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  -->
    <transition
      enter-active-class="duration-200 ease-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-if="menuOpen" class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lm:hidden z-50">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-16 w-auto" src="@/assets/images/logo/logo.png" alt="Logo" />
              </div>
              <div class="-mr-2">
                <button
                  type="button"
                  class="
                    bg-white
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    text-gray-400
                    hover:text-gray-500 hover:bg-gray-100
                    focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500
                  "
                  @click="menuOpen = false"
                >
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <button
                  v-if="$can('read', 'tenant')"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 border-transparent"
                  @click="handleRoute(ORGANISATION_DASHBOARD)"
                >
                  <!-- Heroicon name: chart-bar -->
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                  <span class="ml-3 text-base font-medium text-gray-900"> Dashboard </span>
                </button>

                <button
                  v-if="$can('read', 'booking')"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 border-transparent"
                  @click="handleRoute(ORGANISATION_BOOKING)"
                >
                  <!-- Heroicon name: cursor-click -->
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    />
                  </svg>
                  <span class="ml-3 text-base font-medium text-gray-900"> Bookings </span>
                </button>

                <button
                  v-if="$can('read', 'accommodation')"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 border-transparent"
                  @click="handleRoute(ORGANISATION_ACCOMMODATIONS)"
                >
                  <!-- Heroicon name: shield-check -->
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                  <span class="ml-3 text-base font-medium text-gray-900"> Accommodations </span>
                </button>
                <button
                  v-if="$can('read', 'accommodation')"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 border-transparent"
                  @click="handleRoute(ORGANISATION_ACCOUNT)"
                >
                  <!-- Heroicon name: view-grid -->
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  <span class="ml-3 text-base font-medium text-gray-900"> Account </span>
                </button>
                <button class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 border-transparent" @click="handleRoute(HELP_CENTER)">
                  <!-- Heroicon name: view-grid -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-help-circle text-red-600"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                  <span class="ml-3 text-base font-medium text-gray-900"> Help Center </span>
                </button>
              </nav>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <div>
              <button id="logoutBtn" class="w-full flex items-center justify-center red-button-sm" @click="logout"> Logout </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style>
.navbar .heading {
  @apply text-gray-400 text-lg font-bold hover:text-red-500 dark:text-white;
}

.square {
  position: relative;

  /* height: 400px; */
  overflow: auto;
}

.square .child {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  overflow: auto;
}

.menu-control-wrp {
  position: relative;
}

.menu-contl-wrp .nav-dropdown,
.menu-control-wrp .notifications-dropdown {
  position: absolute;
  top: 55px;
  right: 0;
  z-index: 1;
  min-width: 170px;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.18);
}

.menu-control-wrp .notifications-dropdown {
  min-width: 450px;
}

.menu-control-wrp .nav-dropdown li {
  display: flex;
}

.payment-modal .vs-dialog {
  max-width: 1024px;
}

.menu-control-wrp .nav-dropdown li:hover {
  color: #636363;
  cursor: pointer;
}
.vs-navbar-content {
  position: relative;
}
.menu-control-wrp .nav-dropdown li svg {
  margin-top: auto;
  margin-right: 5px;
  margin-bottom: auto;
}

.menu-control .fa-bars {
  margin: auto;
  margin-right: 0.4rem;
}

.menu-control div {
  margin: auto;
}

.menu-control:where {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  cursor: pointer;
}

.menu-control.active {
  color: #2c3e50;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}
.vs-navbar__group__items {
  padding: 0 !important;
}

.navbar-title-text {
  position: relative;
  padding: 16px 0px;
  padding: 0 16px;
  color: #636363;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.12em;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
